<template>
  <div>
     <el-table v-tableFit :data="DataList" stripe border>
      <el-table-column type="index" label="编号" width="80">
      </el-table-column>
      <el-table-column prop="title" label="标题" min-width="180">
      </el-table-column>
      
      <el-table-column prop="ivst_desc" label="描述" min-width="180">
      </el-table-column>

      <el-table-column prop="qrcode" label="二维码" width="100">
        <template slot-scope="scope">
          <img :src="scope.row.qrcode" style="width: 30px; height: 30px; vertical-align: middle;" onclick="viewImage(this)" />

        </template>
      </el-table-column>

      <el-table-column prop="cdate" label="创建时间" width="180">
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <el-button type="text" @click="remove(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>


    </el-table>
    <!-- <Pagination :pagination="page"></Pagination> -->
  </div>
</template>
<script>
  // import Pagination from '../../com/pagination.vue'
  export default {
    components: {
      // Pagination
    },


    data() {
      return {

        page: {
          current_page: 1,
          next_page: 1,
          pre_page: 1,
          total_page: 1,
          count: 0
        },
        DataList: []

      };
    },
    mounted() {
      this.getList(1);


    },
    methods: {
      getList() {
        this.$http.post("/admin/ivst_more_list", {
          page: this.page.current_page
        }).then(res => {
          this.DataList = res.data.data;
          this.page = res.data.page
        })
      },
      remove(id) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post("/admin/ivst_more_delete", {
            id: id
          }).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功'
            });

            this.getList(this.page.current_page);
          })

        }).catch(() => {

        });
      },
    }
  }
</script>
